.section-missions {
	.bloc-mission > .vc_column-inner  > .wpb_wrapper {
		background: #fff;
		border-bottom: 8px $bleu-fonce solid;
		min-height: 45rem;

		
		-webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
		box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);

		margin-bottom: 35px;

		.wpb_text_column > .wpb_wrapper {
			padding: 15px;
		}
		
	}
}

.overflow-scroll {

	& > div {
		& > div {
			max-height: 40rem;
			overflow: scroll;
		}
	}
}

.page-template-template-page {
	h1 {
	}
	
	h2,h3 {
		font-weight: 300;
		
		&.small-title {
			font-size: 19px;
			margin-top: 0;
			font-weight: 700;
		}
	}
	
}

.page-template-template-home {
	h1,h2 {
		//font-weight: 700;
	}
	
	h3 {
		font-weight: 300;
	}
}

.accordion {
	h3 {
		font-weight: 700;
		color: $bleu-fonce;
		
		a {
			display: block;
		}
	}
	
	.panel {
		position: relative;
		
			i.fa {
				position: absolute;
				top: 10px;
				right: 15px;
			}
	}
	
	.panel-heading {
		border-bottom: 0 none !important;
	}
	
	.panel-body {
		//background: $panel-default-heading-bg;
		color: $bleu-fonce;
		border-top: 0 none !important;
		
		.wrapper {
			border-top: 1px solid $bleu-fonce;
			padding-top: 15px;
		}
	}
}

.wpb_text_column {
	ul {
		list-style: none;
		li {
			position: relative;
			&:before {
				position: absolute;
				content: "";
				left: -20px;
				font-family: "FontAwesome";
			}
		}
	}
}

.owl-nav {
	.owl-prev, .owl-next {
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
	}

	 > button {
		& > i {
			font-size: 2.5rem !important;
			color: $bleu-fonce;
		}
	}
	.owl-prev {
		right: -40px;
	}
	.owl-next {
		left: -40px;
	}
}