#home-slider {
	
	position: relative;
	
	.carousel-indicators {
		li {
			background: #fff;
			border-color: #fff;
			height: 20px;
			width: 20px;
			
			&.active {
				background: $orange;
				border-color: $orange;
				height: 20px;
				width: 20px;
			}
		}
	}
	
	
	/*.bg-slide {
		background: url('../images/bg-slide.png') no-repeat bottom right;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		z-index: 2;
	}*/
	
	.item {
		//position: relative;
		background-size: cover;
		background-position: center center;
		
		.container-texte {
			position: relative;
			
			@media(min-width:$screen-sm) {
				margin: 0 15%;
			}
			
			.container-cadre {
				
				
				position: absolute;
				left:0;
				right: 0;
				top: 0;
				z-index: 3;
				
				height: 480px;
/*				
				@media(max-width: $screen-sm) {
					left: 50%;
					transform: translateX(-50%);
				}
*/				
				z-index: 10;

					a {
						text-decoration: none;
						
						&:hover {
							text-decoration: none;
						}
						
						&.view-more {
							text-decoration: underline;
						}
					}

				a.view-more {
					text-transform: uppercase;
					color: rgba(84,89,89,1);
					font-size: 14px;
					font-weight: 700;
				}
				
				.wrapper-cadre {
					position: relative;
					height: 100%;
				}

				.texte-slide {
					background-color: $bleu-fonce;
					position: absolute;
					
					@media(min-width: $screen-sm) {
						width: 100%;
					}
					
					h1 {
						color: #fff;
						text-shadow: 5px 0px 4px rgba(0, 0, 0, 0.5);
					}

					span {
						color: #fff !important;
					}
					//background: rgba(123,187,42,0.5);
					padding: 15px 5px;
					top: 50%;
					
					transform: translateY(-50%);
					
					@media(max-width: $screen-sm) {
						transform: translate(-50%,-50%);
						left: 50%;
						
					}
					
					p {
						padding: 5px;
						
						@media(max-width: $screen-sm) {
							display: none;
						}
						
						font-size: 16px;
						line-height: 24px;
						text-align: left;
						text-transform: uppercase;
						text-shadow: 5px 0px 4px rgba(0, 0, 0, 0.5);
						
						/*@media(min-width: $screen-sm) {
							font-size: 30px;
							line-height: 40px;
							text-align: left;
						}*/
						
						color: #fff;
					}
					
				}
			}
			
		}
	}
}