@media screen and (max-width: $screen-md){
	#grid[data-columns]::before {
		content: '1 .column.size-1of1';
	}
}

@media screen and (min-width: $screen-md) {
	#grid[data-columns]::before {
		content: '2 .column.size-1of2';
	}
	#grid .bloc-news {
		padding: 15px;
	}
}

/* Again, you’re free to use and define the classes: */
.column { float: left; }
.size-1of1 { width: 100%; }
.size-1of2 { width: 50%; }
.size-1of3 { width: 33.333%; }
