.breadcrumbs {
	margin-top: 15px;
}

.section-ligne {
	
	> .wpb_column > .vc_column-inner  {
		padding-left: 0;
		padding-right: 0;
	}
	
	.container-lignes {
		width: 100%;
		
		.ligne {
			width: 25%;
			height: 8px;
			float: left;
		}
		
		.ligne-jaune {
			background: $jaune;
		}
		
		.ligne-rouge {
			background: $rouge;
		}
	
		.ligne-violet {
			background: $violet;
		}
	
	
		.ligne-vert {
			background: $vert;
		}
	}
}


.fond-blanc {
	h1,h2,h3,h4 {
		color: $bleu-clair;
	}
}

.fond-bleu-ciel {
	background: $bleu-ciel;
	
	h1,h2,h3,h4 {
		color: $bleu-fonce;
	}
	
	.wpb_text_column {
		ul {
			li {
				&:before {
					color: $bleu-clair;
				}
			}
		}
	}
}

.fond-bleu-clair {
	background: $bleu-clair;
	h1,h2,h3,h4 {
		color: #FFF;
	}
}

.texte-bleu {
	color: $bleu-fonce !important;
	
	p, ul li, oli {
		color: $bleu-fonce !important;
	}
}

.texte-bleu-clair {
	color: $bleu-clair !important;
	
	p, ul li, oli {
		color: $bleu-clair !important;
	}
}

.texte-blanc {
	color: #fff !important;
	
	p, ul li, oli {
		color: #fff !important;
	}
}

.border-bottom {
	border-bottom: 8px solid #0a4f75;
	box-shadow: 0 5px 5px 0 rgba(0,0,0,.2);
}



.compteur-chiffre {
	
	&.chiffre-vert {
		
		.chiffre {
			.chiffre-counter {
				background: url('../images/octo-vert.png');
				background-size: contain;
				background-position: center center;
			}
		}
	}

	&.chiffre-rouge {
		
		.chiffre {
			.chiffre-counter {
				background: url('../images/octo-rouge.png');
				background-size: contain;
				background-position: center center;
			}
		}
	}

	&.chiffre-jaune {
		
		.chiffre {
			.chiffre-counter {
				background: url('../images/octo-jaune.png');
				background-size: contain;
				background-position: center center;
			}
		}
	}

	&.chiffre-violet {
		
		.chiffre {
			.chiffre-counter {
				background: url('../images/octo-violet.png');
				background-size: contain;
				background-position: center center;
			}
		}
	}
	.chiffre {
		.chiffre-counter {
			font-weight: 700;
			font-size: 45px;
			line-height: 150px;
			height: 155px;
			width: 155px;
			margin: 0 auto;
		}
	}
}

.flyer {
	margin-bottom: 2rem;
}

.wrap {
	padding-top: 25.2rem;
	
	@media all and (max-width: 992px) {
		padding-top: 14.5rem;
	}

	@media all and (max-width: 767px) {
		padding-top: 19.2rem;
	}
}