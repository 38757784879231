header.banner {

	position: fixed;
	right: 0;
	left: 0;
	z-index: 9999;
	background-color: #ffffff;
	
	.container-menu-top {
		position: relative;
		
		.logo-calm {
			position: absolute;
			right: 0;
			top: 15px;
			
			@media(max-width: $grid-float-breakpoint) {
				display: none;
			}
		}
	}	
	
	.lang-line {
		padding: 5px 0;
		
		background: $jaune;
		
		.textwidget {
			p {
				margin: 0;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 14px;
			}
		}
		
		.lang-selector {
			text-align: right;
		}
		
		p.lang-selector, p.lang-selector a {
			color: $text-color;
			font-size: 14px;
			margin: 0;
		}
	}
	
	.social-barre-mobile {
		background: $orange;
		padding: 5px 15px;
		//border-bottom-right-radius: 10px;
		//border-bottom-left-radius: 10px;
		font-size: 16px;
				
		.textwidget {
			
			text-align: center;
			
			i.fa {
				font-size: 24px;
			}
		}
		
		@media(min-width: $grid-float-breakpoint) {
			display: none;
		}
		
	}
	
	a.brand {
		@media(max-width: $grid-float-breakpoint) {
			display: none;
		}
		
		img {
			width: 300px;
			padding: 30px 0;
		}

		svg {
			width: 380px;
			padding: 30px 0;
		}
	}
	
	
		.logo-mobile {
			@media(min-width: $grid-float-breakpoint) {
				display: none;
			}
			
			img {
				max-width: 100px;
				margin: 15px;
			}
		}
	
	button.navbar-toggle {
		margin-top: 28px;
		
		  border: none;
		  background: transparent !important;
		
		  &:hover {
		    background: transparent !important;
		  }
		
		  .icon-bar {
		    width: 22px;
		    transition: all 0.2s;
		  }
		  .top-bar {
		    transform: rotate(45deg);
		    transform-origin: 10% 10%;
		  }
		  .middle-bar {
		    opacity: 0;
		  }
		  .bottom-bar {
		    transform: rotate(-45deg);
		    transform-origin: 10% 90%;
		  }

		  &.collapsed {
			    .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}		
	}
	
	.navbar-default, .navbar-default .navbar-collapse, .navbar-default .navbar-form {
		border: none;
	}
	
	nav {
		position: relative;
		
		
		
		.social-barre-desktop {
			position: absolute;
			right: 0;
			top:0;
			padding: 5px 0;
			font-size: 16px;
			
			.textwidget {
				text-align: right;
			}
			
			.title {
				font-size: 14px;
				line-height: 16px;
				font-style: italic;
				color: #999;
				font-weight: 300;
				margin: 0 0 10px 0;
			}

			@media(max-width: $grid-float-breakpoint) {
				display: none;
			}
			
			i.fa {
				font-size: 30px;
				color: $vert;
			}
		}

		ul.nav {
			
			@media(min-width: $grid-float-breakpoint) {
				position: absolute;
				right: 0;
				bottom: 0;
			}
			
			
			>li {
				
				&:nth-child(1) {
					border-bottom: 3px solid $vert;
				}
				&:nth-child(2) {
					border-bottom: 3px solid $jaune;
				}
				&:nth-child(3) {
					border-bottom: 3px solid $rouge;
				}
				&:nth-child(4) {
					border-bottom: 3px solid $violet;
				}

				a {
					//font-family: $headings-font-family;
				}
				
			}
			
			li.current-menu-ancestor, li.current-menu-parent, li.current-menu-item {
				> a {
					color: $bleu-clair;
				}
			}
			
			> li {
				> a {
					text-transform: uppercase;
					font-weight: 300;
					font-size: 15px;
					cursor: pointer;
				}
			}
		}
	}
}

.menu-bandeau {
	
	background: $bleu-fonce;
	padding: 15px 0;
	
	&.is_stuck {
		z-index: 10000;
	}
	
	.btn-white {
		font-weight: 700;
		
		&.active {
			background: $bleu-ciel;
		}
	}
	
	.menu-container {
		position: relative;
		
		.menu-collapse {
			background: #fff;
			padding: 15px;
			-webkit-box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.3);
			-moz-box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.3);
			box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.3);

			ul.menu {
				list-style: none;
				padding-left: 0;
				
				> li {
					margin-bottom: 15px;
					&:last-child {
						margin-bottom: 0;
					}
					
					> a {
						text-transform: uppercase;
						font-weight: 400;
						color: $bleu-clair;
						&:hover {
							text-decoration: underline;
						}
					}
					
					> ul.sub-menu {
						list-style: none;
						padding-left: 15px;
						
						> li {
							text-transform: none;
							> a {
								font-weight: 300;
								color: $text-color;
								&:hover {
									text-decoration: underline;
								}
							}
							&:before {
								font-family: "FontAwesome";
								content: "";
								margin-right: 5px;
								color: $bleu-clair;
							}
						}
					}
				}
			}
		}
	}
	
   @media screen and (min-width: 768px)
    {
        .collapsing
        {
            position: absolute !important;
            z-index: 20;
            width: 100%;
            width: calc(100% - 30px);
            top: 50px;
        }
        .collapse.in {
            display: block;
            position: absolute;
            z-index: 20;
            width: 100%;
            width: calc(100% - 30px);
            top: 50px;
        }
        .navbar-collapse
        {
            max-height: none !important;
        }
    }

}