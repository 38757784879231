aside.sidebar {
	
	padding-top: 35px;
	
	p.title {
		text-transform: uppercase;
		color: $bleu-fonce;
		font-size: 18px;
		font-weight: 700;
	}
	
	.widget_recent_entries {
		background: $bleu-ciel;
		padding: 15px;
		
		ul {
			list-style: none;
			padding-left: 15px;
			
			> li {
				position: relative;
				margin-bottom: 15px;
				
				> a {
					color: $text-color;
					font-size: 14px;
					
					&:before {
						position: absolute;
						font-family: "FontAwesome";
						content: "";
						left: -10px;
					}
					
					&:hover {
						color: $bleu-clair;
					}
				}
			}
		}
	}
}