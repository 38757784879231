.listing-partner {
	& > ul {
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		& > li {
			margin: 1rem;
		}
	}
}