.btn {
	@include transition (all  0.3s);
}

.btn.btn-white {
	background: #fff;
	color: $bleu-fonce;
	
	&:hover {
		background: $bleu-ciel;
	}
}