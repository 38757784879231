footer.content-info {
	background: #545454;
	padding-top: 15px;
	padding-bottom: 15px;

	.logo-svg {
		& > svg {
			width: 100%;
			height: auto;
		}
	}
	
	section {
		.title {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 600;
			color: #ccc;
			margin-bottom: 5px;
		}
		
		ul.menu {
			list-style: none;
			padding-left: 0;
			
			> li {
				a {
					
					&:before {
						font-family: "FontAwesome";
						content: "";
						margin-right: 5px;
					}
					
					text-decoration: none;
					color: #ccc;
					font-size: 13px;
					
					&:hover {
						text-decoration: underline;
					}
				}
			} 
		}
	}
}